<template>
<div class="main_wrp">
    <NavBottom />
    <div class="tabtop">
        <span :class="acindx==1?'on':''" @click="showList(1)">待付款</span>
        <span :class="acindx==2?'on':''" @click="showList(2)">待发货</span>
        <span :class="acindx==3?'on':''" @click="showList(3)">待收货</span>
        <span :class="acindx==4?'on':''" @click="showList(5)">已退款</span>
    </div>
    <div class="card_list">
        <UserPad />
        <template v-if="plist.length == 0">
            <div class="no-data">
                抱歉，当前服务器未查询到相关数据！！！
            </div>
        </template>
        <template v-else>
            <div class="card_wrp" v-for="(item,index) in plist" :key="index">
                <div class="card_main">
                    <div class="card_tit">
                        {{item.Orders_Detail[0].OD_Name}}
                    </div>
                    <div class="card_lit">
                        <span>活动类别:</span>{{item.Orders_Detail[0].OD_Variety + " "+ item.Orders_Detail[0].OD_Color + " "+ item.Orders_Detail[0].OD_Size }}
                    </div>
                    <div class="card_lit">
                        <span>订单号: </span>{{item.Orders_Number}}
                    </div>
                    <div class="card_lit">
                        <span>金额: </span> &yen; {{item.Orders_Detail[0].OD_TotalPrice}} 元
                    </div>
                </div>
                <div class="btnswrp">
                    <router-link v-if="acindx==2 ||acindx==3" :to="'/ticketBuyin/'+item.Orders_ID">查看票券</router-link>
                    <button  v-if="item.Insurance_Status == 1 && item.Insurance_RefundRequest == 0" @click="askMoneyBack(item.Orders_ID)">申请退款</button>                    
                </div>
            </div>
            <a href="javascript:void(0);" class="more-data" @click="loadmore(acindx)">加载更多</a>
        </template>
    </div>
    <div class="remark" v-if="remarkShow">
        <span class="closeBtn" @click="closeRemark">关闭</span>
        <h5>申请退款原因</h5>
        <textarea v-model="remark" cols="30" rows="10">

        </textarea>
        <button @click="sureRemark">确定</button>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            domin: this.domin,
            status: 0,
            acindx: 0,
            pageindex: 1,
            gindx: 0,
            types: [],
            plist: [],
            member: "",
            remark:"",
            currentId:0,
            remarkShow:false
        }
    },
    created(opt) {
        this.status = this.$route.params.status;
        this.acindx = this.$route.params.status;
        if(this.$route.params.status == 5) {
            this.acindx =  4
        }
        console.log('status:', this.$route.params.status)
        this.showList(this.$route.params.status)
    },

    methods: {
        closeRemark(){
            this.remarkShow = false;
            this.remark = "";
            console.log("closeRemark")
        },
        sureRemark(){
            if(this.remark == ''){
                alert('请填写退款原因');
                return;
            }
            if(this.currentId == 0){
                alert('请选择要退款的订单');
                return;
            }
            this.remarkShow = false;
            this.moneyBack(this.currentId)
        },
        askMoneyBack(id){
            this.remarkShow = true
            this.remark = ""
            this.currentId = id
            console.log("id",this.currentId)
        },
        moneyBack:function (id) {
            
            this.wpost({
                url: '/Ajax/WordWelfare/refundrequest',
                data: {
                    id: id,
                    remark: this.remark
                },
                succ:  (res) => {
                    alert('申请成功');
                    this.showList(this.status)                    
                }
            })
        },
        showList: function (id) {

            let that = this
            this.plist = []
            this.acindx = id
            if(id == 5){
                this.acindx = 4
            }
            this.pageindex = 1
            this.wpost({
                url: '/Ajax/WordWelfare/getorderslist',
                data: {
                    status: id,
                    pagesize: 20,
                    pageindex: that.pageindex
                },
                succ: function (data) {
                    // console.log("result:",data)
                    that.plist = data.list
                    that.member = data.member
                }
            })
        },
        loadmore: function (id) {
            let that = this
            // console.log('loadmore data')
            this.wpost({
                url: '/Ajax/WordWelfare/getorderslist',
                data: {
                    status: id,
                    pagesize: 20,
                    pageindex: that.pageindex += 1
                },
                succ: function (data) {
                    if (data.list.length > 0) {
                        that.plist = that.plist.concat(data.list)
                    } else {
                        that.pageindex -= 1
                        alert('当前无更多数据...');

                    }

                }
            })
            // console.log("pageindex:", that.pageindex)
        },

    }
}
</script>

<style scoped>
.closeBtn{
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 12px;
    color: #E91F2F;
}
.remark{
    width: 97%;
    position: fixed;
    top: 12vh;
    left: 1.5%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 6px #ccc;
}
.remark h5{
    text-indent: 6px;
    margin: 12px 0;
}
.remark textarea{
    width: 96%;
    margin: 0 auto;
    display: block;
    resize:none
}
.remark button{
    height: 33px;
    width: 70px;
    background: #ff5a00;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 6px;
    margin: 10px auto;
    display: block;
}
.wtop {
    position: relative;
}

.wtop image {
    width: 100%;
}

.wtopIn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px
}

.wtips {
    position: absolute;
    top: 6px;
    right: 12px;
    color: #fff;
    font-size: 24rpx;
}

.statusText {

    position: absolute;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 1px solid #F0AD4E;
    box-shadow: 0 0 3px 1px #F0AD4E;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    right: 6px;
    bottom: 12px;
}

.card_main .statusText text {
    margin: 0;
    color: #F97A0A;
    font-size: 11px;
}

.card_main {
    position: relative;
}

.userInfo {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    color: #fff;
}

.wimg {
    width: 70px;
    height: 70px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;

}

.wimg image {
    width: 70px;
    height: 70px;
}

.wtext {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.wtext text {
    font-size: 25rpx;
}

.warr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    color: #fff;
}

.main_wrp {
    height: 100vh;
    position: relative;
}

.top_head {
    height: 51px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f97a0a;
}

.btnswrp {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    border: 1px solid #F0F0F0;
}

.btnswrp a {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    display: inline-block;
    padding: 2px 6px;
    margin: 6px 0;
    color: #888;
    border: 1px solid #888;
    border-radius: 20px;
    font-size: 13px;
}
.btnswrp button{
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    display: inline-block;
    padding: 2px 6px;
    margin: 6px 0;
    color: #999;
    border: 1px solid #999;
    border-radius: 20px;
    font-size: 13px;
}
.card_wrp button {
    margin: 10px 5px;
}

.card_list {
    padding-top: 41px;
    padding-bottom: 70px;
    margin-bottom: 70px;
    box-sizing: border-box;
}

.tabtop {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: #fff;
}

.tabtop span {
    display: block;
    width: 25%;
    color: #3F536E;
    font-size: 14px;
    padding: 8px 0;
    text-align: center;
    border: none;
    background-color: none;
}

.tabtop span.on {
    color: #F97A0A;
}

.tabtop span:active {
    background-color: #ECECEC;
}

.card_intros {
    font-size: 32rpx;
    line-height: 1.6;
    padding: 10px;
    font-weight: bold;
}

.card_wrp {
    margin: 10px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 6px;
}

.card_lit {
    padding: 2px 10px;
}

.card_tit {
    color: #333;
    padding: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    border-bottom: 1px solid #F0F0F0;
    overflow: hidden;
    font-size: 15px;
    margin: 6px 0;
}

.card_main {
    font-size: 14px;
    color: #E91F2F;
}

.card_main text {
    margin-right: 6px;
    font-size: 13px;
    color: #333;
}

.nav_county {
    width: 150upx;
    text-align: center;
    border-right: 1px solid #DEDEDE;
    box-sizing: border-box;
}

.nav_county text {
    display: block;
    padding: 12px 0;
    font-size: 14px;
    background-color: #ececec;
    color: #888;
    border: 1px solid #dedede;
    border-top: none;
    border-right: none;
}

.nav_county text.ac {
    color: #f97a0a;
    background: #fff;
}
</style>
